import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Images } from "../../Images/index";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { loginModal } from "../Login/actions";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

const Footer = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const footerList = [
    {
      list: [
        { title: t("About us"), path: "/about-us" },
        { title: t("Contact Us"), path: "/support" },
      ],
      title: "Riviera Bet",
    },
    {
      list: [
        { title: t("Sports"), path: "/sports" },
        { title: t("Casino"), path: "/casino" },
        { title: t("Live Casino"), path: "/live-casino" },
      ],
      title: t("Products"),
    },
    {
      list: [
        { title: t("Pragmatic-Play"), path: "/live-casino" },
        { title: t("Evolution"), path: "/live-casino" },
        { title: t("Ezugi"), path: "/live-casino" },
        { title: t("Vivo Gaming"), path: "/live-casino" },
      ],
      title: t("Live Casino"),
    },
    {
      list: [
        { title: t("Deposits"), path: "/cashier/wallets" },
        { title: t("Withdrawals"), path: "/cashier/wallets" },
      ],
      title: t("Secure Banking"),
    },
    {
      list: [
        { title: t("Terms and Conditions"), path: "/terms&conditions" },
        { title: t("sports_book_rules"), path: "/sportsbook-rules" },
        { title: t("Responsible Gaming"), path: "/responsible-gaming" },
        { title: t("Self-Exclusion"), path: "/self-exclusion" },
        { title: t("Dispute Resolutions"), path: "/dispute-resolution" },
        { title: t("AML"), path: "/aml-policy" },
        { title: t("Fairness & Testing"), path: "/fairness-rng-testing" },
        { title: t("Privacy"), path: "/privacy-policy" },
        { title: t("Account Payouts and Bonus"), path: "/accounts-payouts-bonus" },
      ],
      title: t("Terms & Regulations"),
    },
  ];

  function toggleTab(e) {
    const tab = e.currentTarget.nextElementSibling;
    console.log(tab);
    if (tab.classList.contains("active")) {
      tab.classList.remove("active");
      return;
    } else {
      tab.classList.add("active");
    }
  }

  const handleClick = (path) => {
    const Token = getItemFromLocalStorage("Token");
    let ispresent = path.includes("/cashier");
    if (!Token && ispresent) {
      props.loginModal(true);
    } else history.push(path);
  };
  let location = window.location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <footer className="footer main-footer">
      <div className="custom-hr-line"></div>
      <div className="footer-payments block-mg">
        <div className="footer-payments-container">
          <img
            width="41"
            height="25"
            loading="lazy"
            className="footer-payments-img"
            src={Images.mastercard}
            alt="mastercard icon"
          />
          <img
            width="81"
            height="25"
            loading="lazy"
            className="footer-payments-img"
            src={Images.visa}
            alt="visa icon"
          />
          <img
            width="143"
            height="25"
            loading="lazy"
            className="footer-payments-img"
            src={Images.neteller}
            alt="neteller icon"
          />
          <img
            width="141"
            height="25"
            loading="lazy"
            className="footer-payments-img"
            src={Images.paysafecard}
            alt="pay safe card icon"
          />
          <img
            width="73"
            height="25"
            loading="lazy"
            className="footer-payments-img"
            src={Images.skrill}
            alt="skrill icon"
          />
          <img
            width="101"
            height="25"
            loading="lazy"
            className="footer-payments-img"
            src={Images.paypal}
            alt="paypal icon"
          />
        </div>
        <div className="divider"></div>
        <img
          width="46"
          height="46"
          loading="lazy"
          className="footer-ageLimit"
          src={Images.age_limit}
          alt=""
        />
      </div>
      <div className="custom-hr-line"></div>
      <div className="footer-menu-block">
        <div className="footer-menu">
          {footerList.map((list, i) => {
            return (
              <div
                key={list.title}
                className="footer-menu-block">
                <button
                  onClick={(e) => toggleTab(e)}
                  className="footer-menu-header">
                  <p className="footer-menu-title">{list.title}</p>
                  <img
                    width="10"
                    height="7"
                    loading="lazy"
                    src={Images.downArrowGray}
                    alt="icon"
                  />
                </button>
                <div className="footer-menu-tab">
                  {list.list.map(({ title, path }) => {
                    return (
                      <div
                        key={title}
                        className="footer-menu-link-block">
                        <button
                          onClick={() => handleClick(path)}
                          className={`${window?.location?.pathname?.includes(path) ? "active-clr" : ""} footer-menu-link`}>
                          {title}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="footer-slogan"
        style={{
          background: `url(${themeMode === "light" ? Images.safeBetterBgLight : Images.casino_2_bg}) center no-repeat`,
          backgroundSize: "cover",
        }}>
        <div className="footer-slogan-content">
          <img
            className={`${themeMode === "light" ? "black-image" : ""} footer-slogan-logo`}
            width="165"
            height="60"
            src={Images.RivieraBetfooterLogo}
            alt="RivieraBetfooterLogo"
          />
          <div className="">
            <div className="footer-slogan-title">SAFER.BETTER.TOGETHER</div>
            <div className="footer-slogan-text">
              Play responsibly
              {/* {t("Playing responsible with limits")} */}
            </div>
          </div>
        </div>
      </div>

      <div className="footer-text">
        {t("copyright_title")}
        <br />
        <br />
        RivieraBet is operated by R. Bostock Enterprises B.V. (reg. nr. 159489), Managing Director Kurason Trust, Schottegatweg Oost 10 Unit 1-9 Bon Bini Business Center, Curaçao, and regulated under gaming license nr. 1668/JAZ issued to Curaçao eGaming, Authorized and Regulated by the Government of Curacao.
        <br />
        <br />
        By accessing, or continuing to use or browse this website, you agree that we may use certain cookies to improve your experience with us. We only use cookies which will enhance your experience and which will not interfere with your privacy. In order to register for this website, the user is required to accept the <NavLink to="/terms&conditions">General Terms and Conditions</NavLink>. In the event the <NavLink to="/terms&conditions">General Terms and Conditions</NavLink> are updated, existing users may choose to discontinue using the products and services before the said update shall become effective, which is a minimum of two weeks after it has been announced.
      </div>
      <div className="d-flex justify-content-center align-items-center pt-5">
        {/* eslint-disable-next-line */}
        <a
          href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbmxsTjBWT05rTldOMmRTYldsMU5GTk5jbTVKVEZFOVBTSXNJblpoYkhWbElqb2lUV2RvVm0xSGNrMHdZM04yVldreU5UazNXR2RHWnowOUlpd2liV0ZqSWpvaU9EazBZelZoTURZNFpEUm1Oak5oTVdJMk9XSXpNelkzWTJFeU9UVTJNRGd5TlRRMk56Rm1ZbVZqWkRoallqYzFOek15Tm1RMFl6RXhOV1l4T1RJeFlpSXNJblJoWnlJNklpSjk="
          target="_blank">
          <img
            src="https://seal.cgcb.info/1c0246df-1aa7-485a-a24c-21ae5e730000"
            alt=""
            width="100"
          />
        </a>
      </div>
    </footer>
  );
};

export default connect(null, {
  loginModal,
})(Footer);
